import {useEffect,  Component, useRef}  from 'react';
import { initTranslate } from "../../google-translate/translate";
import { isInteger } from 'lodash';
interface Props {
    darkmode: boolean;
    branding: any;
    configLanguages: any;
}
export const GoogleTranslate = ({ darkmode, branding, configLanguages}: Props) => {
    const languageRef = useRef<any>(null);
    const isInitialized = useRef(false);

    useEffect(() => {
        //if (!isInitialized.current && configLanguages) {
        const existingElement = document.getElementById("google_translate_element");
        if (existingElement) {
            existingElement.remove();
        }

        // Create a new div element for Google Translate
        const container = document.getElementById("google_translate_container");
        if (container) {
            const newElement = document.createElement("div");
            newElement.id = "google_translate_element";
            newElement.setAttribute("data-theme", darkmode ? "dark" : "");
            container.appendChild(newElement);
        }

        languageRef.current = configLanguages;
        const remove = initTranslate(languageRef.current);
        isInitialized.current = true;
        return () => {
            remove();
            isInitialized.current = false;
        };
        //}
    }, [configLanguages]);

    useEffect(() => {
        if (branding) {
            languageRef.current = configLanguages;
        }
    }, [configLanguages]);

    return (
        <div id="google_translate_container">
            <div id="google_translate_element" data-theme={darkmode ? "dark" : ""}></div>
        </div>
    );
}